import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { SxProps, Theme } from '@mui/material/styles';

interface Props {
  style?: SxProps<Theme>;
  title?: string;
  description?: string;
  className?: string;
}

export function Loading({ style, title, className, description }: Props) {
  return (
    <Box display="flex" className={className ?? 'h-screen'}>
      <Box
        sx={style ?? { mx: 'auto', my: 'auto', px: 2 }}
        justifyContent="center"
        alignItems="center"
      >
        {!!title && (
          <Typography variant="h4" textAlign="center">
            {title}
          </Typography>
        )}
        <Box display="flex" justifyContent="center">
          <CircularProgress color="primary" sx={{ mx: 'auto', mt: 2 }} />
        </Box>
        {!!description && (
          <Typography variant="h6" textAlign="center" sx={{ mt: 2 }}>
            {description}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
