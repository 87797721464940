import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: { main: '#528075' },
    info: { main: '#FFFFFF' },
  },
  typography: {
    fontFamily: 'DM Sans',
  },
});
