import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  clientState,
  refreshTokenState,
  tokenState,
  userState,
} from '../states/auth';

interface Params {}

export function useRefreshToken(
  options?: UseMutationOptions<unknown, AxiosError, Params>
) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const source = searchParams.get('source');
  const client = useRecoilValue(
    clientState({
      contentType: 'application/json',
    })
  );
  const setToken = useSetRecoilState(tokenState);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenState);
  const setUser = useSetRecoilState(userState);

  return useMutation<unknown, AxiosError, Params>(
    _ =>
      client.post('/refreshToken', {
        refreshToken,
        appName: 'PracticeInterviews',
      }),
    {
      ...options,
      onSuccess(res: any) {
        setToken(res.data.token);
        setRefreshToken(res.data.refreshToken);
      },
      onError(_e) {
        setToken('');
        setRefreshToken('');
        setUser(null);
        const params = new URLSearchParams(source ? { source } : {});
        navigate(`/?${params}`);
      },
    }
  );
}
