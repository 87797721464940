export const APP_PATHS = {
  any: '*',
  enterEmail: '/enter-email',
  enterPassword: '/enter-password',
  createAccount: '/create-account',
  selectJobTitle: '/select-job-title',
  startAnswering: '/start-answering',
  selectQuestionType: '/select-question-type',
  feedback: '/feedback',
  summary: '/summary',
  enterVerificationCode: '/enter-verification-code',
  enterForgotPasswordVerificationCode:
    '/enter-forgot-password-verification-code',
  createNewPassword: '/create-new-password',
  answerFeedback: '/answer-feedback',
  analytics: '/analytics',
  resources: '/resources',
  account: '/account',
  checkout: '/checkout',
  welcome: '/welcome',
  onboardingJobSearch: '/onboarding-job-search',
  onboardingJobDescription: '/onboarding-job-description',
  onboardingFeedback: '/onboarding-feedback',
  onboardingResult: '/onboarding-result',
  home: '/home',
  jobCategories: '/job-categories',
  chooseCompany: '/choose-company',
  createJobTitle: '/create-job-title',
  confirmInterview: '/confirm-interview',
  dashboard: '/dashboard',
  users: '/users',
  adminDashboard: 'https://fractalai-web-prod-4f1fd93c253e.herokuapp.com/pi-auth',
};
