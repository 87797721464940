import LogoImage from '../assets/images/logo.png';

export default function Logo(props: React.ComponentProps<'a'>) {
  return (
    <a
      href="https://www.practiceinterviews.com/"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        display: 'inline-block',
      }}
      {...props}
    >
      <img src={LogoImage} width={110} height={38} alt="Practice Interviews" />
    </a>
  );
}
