import { atom } from 'recoil';

type UiStateType = {
  menuOpen: boolean;
};

export const uiState = atom<UiStateType>({
  key: 'ui_state',
  default: {
    menuOpen: true,
  },
  effects: [
    ({ onSet, setSelf }) => {
      const uiState = localStorage.getItem('ui_state');
      if (uiState) {
        setSelf(JSON.parse(uiState));
      }

      onSet(newValue => {
        localStorage.setItem('ui_state', JSON.stringify(newValue));
      });
    },
  ],
});
