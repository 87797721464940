import { Card } from '@mui/material';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{}>;

export function Footer({ children }: Props) {
  return (
    <Card
      variant="outlined"
      className="!border-t !border-textGray !rounded-none !p-2  "
    >
      {children}
    </Card>
  );
}
