import EmailIcon from '@mui/icons-material/Email';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Avatar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import { useMe } from '../middleware/useMe';
import { APP_PATHS } from '../routes/paths';
import { tokenState } from '../states/auth';
import {
  homeButtonClickedState,
  supportVisibilityState,
} from '../states/header';
import { stripUsername } from '../utils/user';
import IsFreeTooltip from './IsFreeTooltip';
import Logo from './Logo';
import PIDialog from './PIDialog';

interface Props {
  showHeaderButtons?: boolean;
}

interface NavItem {
  text: string;
  path: string;
  disabled?: boolean;
  tooltip?: string;
  action: () => void;
}

const DRAWER_WIDTH = 240;

export function Header({ showHeaderButtons = true }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const setHomeButtonClicked = useSetRecoilState(homeButtonClickedState);
  const setSupportVisibilityState = useSetRecoilState(supportVisibilityState);
  const token = useRecoilValue(tokenState);
  const theme = useTheme();
  const [menuOpened, setMenuOpened] = useState<boolean>(false);
  const [emailDialogDismissed, setEmailDialogDismissed] =
    useState<boolean>(true);

  const { user, isFree } = useMe();

  const { isMobile } = useWindowDimensions();

  const goToHome = useCallback(() => {
    if (location.pathname !== APP_PATHS.startAnswering) {
      navigate('/');
      setHomeButtonClicked(false);
    } else {
      setHomeButtonClicked(true);
    }
  }, [navigate, location, setHomeButtonClicked]);

  const openLink = useCallback((url: string) => {
    const newTab = shouldOpenInNewTab(window.location.pathname);
    window.open(url, newTab ? '_blank' : '_self');
  }, []);

  const shouldOpenInNewTab = (pathname: string): boolean => {
    const paths = ['/select-question-type', '/start-answering', '/feedback'];
    return paths.some(path => pathname.endsWith(path));
  };

  const showCustomerSupport = useCallback(() => {
    setSupportVisibilityState('maximized');
  }, [setSupportVisibilityState]);

  const navItems: NavItem[] = useMemo(
    () => [
      { text: 'Practice', path: '/', action: goToHome },
      {
        text: 'Answer Feedback',
        path: APP_PATHS.answerFeedback,
        action: () => openLink(APP_PATHS.answerFeedback),
      },
      {
        text: 'Analytics',
        disabled: isFree,
        tooltip: 'Upgrade to PREMIUM to access this feature!',
        path: APP_PATHS.analytics,
        action: () => openLink(APP_PATHS.analytics),
      },
      {
        text: 'Resources',
        disabled: isFree,
        tooltip: 'Upgrade to PREMIUM to access this feature!',
        path: APP_PATHS.resources,
        action: () => openLink(APP_PATHS.resources),
      },
    ],
    [isFree, goToHome, openLink]
  );

  const renderNavItems = (isMobile: boolean) => {
    return navItems.map((item, index) =>
      isMobile ? (
        <ListItem disablePadding key={index}>
          <IsFreeTooltip isFree={item.disabled} tooltip={item.tooltip ?? ''}>
            <span>
              <ListItemButton
                disabled={item.disabled}
                onClick={() => {
                  item.action();
                  setMenuOpened(false);
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      color="primary"
                    >
                      {item.text}
                    </Typography>
                  }
                />
              </ListItemButton>
            </span>
          </IsFreeTooltip>
        </ListItem>
      ) : (
        <IsFreeTooltip
          isFree={item.disabled}
          tooltip={item.tooltip ?? ''}
          key={index}
        >
          <span>
            <Button
              disabled={item.disabled}
              key={item.text}
              onClick={item.action}
              sx={styles.button}
            >
              {item.text}
            </Button>
          </span>
        </IsFreeTooltip>
      )
    );
  };

  const drawer = !token ? null : (
    <Drawer
      variant="temporary"
      open={menuOpened}
      onClose={() => setMenuOpened(false)}
      ModalProps={{ keepMounted: true }}
      sx={styles.drawer}
    >
      <List>{renderNavItems(true)}</List>
    </Drawer>
  );

  return (
    <Container sx={styles.container}>
      {drawer}
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        sx={styles.header}
      >
        {showHeaderButtons && (
          <>
            <Box alignItems="center" sx={styles.menuButton}>
              {!token ? (
                <Button
                  onClick={() => navigate(APP_PATHS.enterEmail)}
                  sx={styles.button}
                >
                  Login
                </Button>
              ) : (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={() => setMenuOpened(prev => !prev)}
                >
                  <MenuIcon color="primary" />
                </IconButton>
              )}
            </Box>

            <Box alignItems="center" sx={styles.buttons}>
              {!token && (
                <Button
                  onClick={() => navigate(APP_PATHS.enterEmail)}
                  sx={styles.button}
                >
                  Login
                </Button>
              )}
            </Box>
          </>
        )}

        <Box display="flex" alignItems="center">
          {!token || !showHeaderButtons ? null : (
            <>
              {user?.isFree && (
                <Button
                  variant="contained"
                  onClick={() => openLink(APP_PATHS.account)}
                  sx={styles.button}
                >
                  Upgrade
                </Button>
              )}
              <IconButton onClick={() => setEmailDialogDismissed(false)}>
                <EmailIcon color="action" />
              </IconButton>
              <IconButton onClick={showCustomerSupport}>
                <LiveHelpIcon color="action" />
              </IconButton>
              <div
                style={{ cursor: 'pointer', marginLeft: 4 }}
                onClick={() => openLink(APP_PATHS.account)}
              >
                {isMobile ? (
                  <Avatar
                    children={stripUsername(user?.firstName ?? '')}
                    sx={{ ...styles.avatar, marginRight: 1 }}
                  />
                ) : (
                  <Box
                    sx={styles.usernameContainer}
                    display="flex"
                    alignItems="center"
                    borderRadius={100}
                  >
                    <Box minWidth={100} sx={styles.username}>
                      <Typography>{user?.firstName}</Typography>
                    </Box>
                    <Avatar
                      children={stripUsername(user?.firstName ?? '')}
                      sx={styles.avatar}
                    />
                  </Box>
                )}
              </div>
            </>
          )}
          <Logo />
        </Box>
      </Box>
      <PIDialog
        title="Email us"
        description={
          <span>
            Need help? Found a bug? Have a feature request? Please feel free to
            contact us:{' '}
            <b>
              <a
                href="mailto: jeff@practiceinterviews.com"
                style={{
                  textDecoration: 'underline',
                  color: theme.palette.primary.main,
                }}
              >
                jeff@practiceinterviews.com
              </a>
            </b>
          </span>
        }
        open={!emailDialogDismissed}
        onClose={() => {
          setEmailDialogDismissed(true);
        }}
        buttons={[
          {
            text: 'Okay',
            color: theme.palette.primary.main,
          },
        ]}
      />
    </Container>
  );
}

const styles: { [key: string]: SxProps<Theme> } = {
  container: {
    paddingTop: 1,
    paddingBottom: 1,
    zIndex: 999,
    minHeight: 68,
    alignItems: 'center',
    display: 'flex',
    minWidth: 'calc(100% + 70px)',
    backgroundColor: 'white',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    margin: ' 0 -40px',
  },
  header: {
    padding: 0,
    flex: 1,
  },
  usernameContainer: {
    marginRight: 2,
    backgroundColor: '#F5F5F5',
    padding: 1,
  },
  username: {
    marginLeft: 2,
    marginRight: 2,
  },
  avatar: {
    fontSize: 12,
    width: 24,
    height: 24,
  },
  button: {
    textTransform: 'none',
    marginRight: 2,
    fontWeight: 600,
  },
  upgradeButton: {
    textTransform: 'none',
    marginRight: 2,
    fontWeight: 600,
  },
  buttons: {
    display: { xs: 'none', sm: 'flex' },
    flex: 1,
  },
  menuButton: {
    display: { xs: 'flex', sm: 'none' },
    flex: 1,
  },
  drawer: {
    display: { xs: 'block', sm: 'none' },
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: DRAWER_WIDTH,
    },
  },
};

export default Header;
