import { useEffect } from 'react';

export function useValidateProtocol({ host }: { host: string }) {
  useEffect(() => {
    // redirect http to https manually
    if (
      window.location.host.indexOf(host) >= 0 &&
      window.location.protocol !== 'https:'
    ) {
      window.location.protocol = 'https';
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
