import styled from '@emotion/styled';
import { Tooltip, tooltipClasses, TooltipProps, useTheme } from '@mui/material';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => {
  const theme = useTheme();

  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 16,
      textAlign: 'center',
    },
  };
});

const IsFreeTooltip = ({
  isFree,
  tooltip,
  children,
  props,
}: {
  isFree?: boolean;
  tooltip: string;
  children: React.ReactElement;
  props?: TooltipProps;
}) => {
  if (isFree) {
    return (
      <LightTooltip title={tooltip} {...props} enterTouchDelay={0}>
        {children}
      </LightTooltip>
    );
  } else {
    return children;
  }
};

export default IsFreeTooltip;
