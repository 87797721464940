import { Snackbar, Typography } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { useInterval } from './useInterval';
import { useOnChange } from './useOnChange';

export function useAppUpdateCheck() {
  const [visible, setVisible] = useState<boolean>(false);
  const [updatedAt, setUpdatedAt] = useState<number>(0);

  useInterval({
    interval: 1000 * 30,
    callback: async () => {
      const response = await fetch('version.json');
      const versionData = await response.json();
      const timestampInSeconds = versionData.updatedAt / 1000;
      setUpdatedAt(timestampInSeconds);
      console.info(
        'app update check at: ',
        moment(timestampInSeconds).format('MM/DD/YYYY hh:mm:ss')
      );
    },
  });

  useOnChange({
    value: updatedAt,
    defaultValue: 0,
    skipDefaultCheck: true,
    onChange: () => setVisible(true),
  });

  return (
    <Snackbar
      open={visible}
      onClick={() => window.location.reload()}
      message={
        <Typography sx={{ cursor: 'pointer' }}>
          A new version is available! <b>Click here</b> to refresh now.
        </Typography>
      }
      autoHideDuration={10000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ mt: 8 }}
      key="auto-update-snack-bar"
    />
  );
}
