import { Theme, useMediaQuery } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Constants } from '../data/constants';

export function useWindowDimensions() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const getAppropriateHeight = useCallback(
    (heightLimit: number) => {
      const rest =
        (windowHeight - heightLimit) % Constants.TEXTFIELD_ONE_ROW_HEIGHT;
      const tailoredHeight = windowHeight - heightLimit - rest;
      const realHeight =
        tailoredHeight > Constants.TEXTFIELD_ONE_ROW_HEIGHT
          ? tailoredHeight
          : Constants.TEXTFIELD_ONE_ROW_HEIGHT;

      return realHeight;
    },
    [windowHeight]
  );

  useEffect(() => {
    const updateWindowHeight = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateWindowHeight);

    return () => {
      window.removeEventListener('resize', updateWindowHeight);
    };
  }, []);

  return { isMobile, windowHeight, getAppropriateHeight };
}
