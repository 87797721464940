import { atom } from 'recoil';

export type ModularFeedbacksType = {
  [key: string]: any;
} | null;

export const modularFeedbacksState = atom<ModularFeedbacksType>({
  key: 'modularFeedbacks',
  default: null,
});
