import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { useRecoilValue } from 'recoil';
import { Job } from '../data/job';
import { clientState } from '../states/auth';

interface Params {}

export function useJobs(
  options?: UseMutationOptions<
    { data: { success: boolean; data: Array<Job> } },
    AxiosError,
    Params
  >
) {
  const client = useRecoilValue(
    clientState({
      contentType: 'application/json',
      apiVersion: 1,
    })
  );

  return useMutation<
    { data: { success: boolean; data: Array<Job> } },
    AxiosError,
    Params
  >(() => client.get('/jobs'), {
    ...options,
  });
}
