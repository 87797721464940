import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';

interface Props {
  title: string;
  description?: string | React.ReactNode;
  open: boolean;
  onClose: () => void;
  buttons: Array<{
    text: string;
    onPress?: () => void;
    disabled?: boolean;
    keepDialogOpen?: boolean;
    color?: string;
  }>;
  node?: React.ReactNode;
  minWidth?: number | string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PIDialog({
  open,
  buttons,
  title,
  description,
  onClose,
  node,
  minWidth,
}: Props) {
  const theme = useTheme();
  const onClick = React.useCallback(
    (button: {
      text: string;
      onPress?: () => void;
      keepDialogOpen?: boolean;
    }) => {
      if (button.onPress != null) {
        button.onPress();
      }
      if (!button.keepDialogOpen) {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        keepMounted
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={minWidth ? { minWidth } : null}>
          {description != null && (
            <DialogContentText>{description}</DialogContentText>
          )}
          {node}
        </DialogContent>
        <DialogActions>
          {buttons.map((button, index) => (
            <Button
              key={index}
              disabled={button.disabled}
              variant="contained"
              style={{
                textTransform: 'none',
                minHeight: '100%',
                backgroundColor: button.color
                  ? button.color
                  : theme.palette.primary.main,
                color: '#ffffff',
              }}
              onClick={() => onClick(button)}
            >
              {button.text}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
