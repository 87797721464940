import { MoreVertOutlined } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from '../../components/MainLayout';
import { useOnChange } from '../../hooks/useOnChange';
import { useMe } from '../../middleware/useMe';

type Column = {
  id:
    | 'id'
    | 'name'
    | 'email'
    | 'questions_practiced'
    | 'avg_interview_score'
    | 'last_question_practiced';
  label: string;
  minWidth?: number;
  align?: 'center' | 'left' | 'right';
};

const columns: readonly Column[] = [
  { id: 'id', label: 'No', minWidth: 70, align: 'center' },
  { id: 'name', label: 'Name', minWidth: 200 },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'questions_practiced',
    label: 'Questions Practiced',
    align: 'right',
  },
  {
    id: 'avg_interview_score',
    label: 'Avg Interview Score',
    align: 'right',
  },
  {
    id: 'last_question_practiced',
    label: 'Last Question Practiced',
  },
];

const userData = [
  {
    id: 1,
    name: 'Juan Garcia',
    email: 'juan.garcia@gmail.com',
    questions_practiced: 39,
    avg_interview_score: 79,
    last_question_practiced: '2024-10-08',
  },
  {
    id: 2,
    name: 'Aaron Cohen',
    email: 'aaron.cohen@gmail.com',
    questions_practiced: 28,
    avg_interview_score: 53,
    last_question_practiced: '2024-10-10',
  },
  {
    id: 3,
    name: 'Jamal Williams',
    email: 'jamal.williams@gmail.com',
    questions_practiced: 44,
    avg_interview_score: 54,
    last_question_practiced: '2024-10-20',
  },
  {
    id: 4,
    name: 'Aaliyah Davis',
    email: 'aaliyah.davis@gmail.com',
    questions_practiced: 35,
    avg_interview_score: 65,
    last_question_practiced: '2024-10-15',
  },
  {
    id: 5,
    name: 'Xiang Zhang',
    email: 'xiang.zhang@gmail.com',
    questions_practiced: 99,
    avg_interview_score: 78,
    last_question_practiced: '2024-10-10',
  },
  {
    id: 6,
    name: 'Ravi Patel',
    email: 'ravi.patel@gmail.com',
    questions_practiced: 79,
    avg_interview_score: 95,
    last_question_practiced: '2024-10-08',
  },
  {
    id: 7,
    name: 'Ming Lee',
    email: 'ming.lee@gmail.com',
    questions_practiced: 53,
    avg_interview_score: 67,
    last_question_practiced: '2024-10-10',
  },
  {
    id: 8,
    name: 'Rachel Smith',
    email: 'rachel.smith@gmail.com',
    questions_practiced: 54,
    avg_interview_score: 55,
    last_question_practiced: '2024-10-19',
  },
  {
    id: 9,
    name: 'David Johnson',
    email: 'david.johnson@gmail.com',
    questions_practiced: 51,
    avg_interview_score: 77,
    last_question_practiced: '2024-10-18',
  },
  {
    id: 10,
    name: 'Santiago Lopez',
    email: 'santiago.lopez@gmail.com',
    questions_practiced: 93,
    avg_interview_score: 51,
    last_question_practiced: '2024-10-16',
  },
  {
    id: 11,
    name: 'Maria Ramirez',
    email: 'maria.ramirez@gmail.com',
    questions_practiced: 79,
    avg_interview_score: 85,
    last_question_practiced: '2024-10-09',
  },
  {
    id: 12,
    name: 'Lakshmi Sharma',
    email: 'lakshmi.sharma@gmail.com',
    questions_practiced: 93,
    avg_interview_score: 92,
    last_question_practiced: '2024-10-20',
  },
  {
    id: 13,
    name: 'Chen Wang',
    email: 'chen.wang@gmail.com',
    questions_practiced: 70,
    avg_interview_score: 73,
    last_question_practiced: '2024-10-13',
  },
  {
    id: 14,
    name: 'Jacob Cohen',
    email: 'jacob.cohen@gmail.com',
    questions_practiced: 83,
    avg_interview_score: 97,
    last_question_practiced: '2024-10-10',
  },
  {
    id: 15,
    name: 'Michael Brown',
    email: 'michael.brown@gmail.com',
    questions_practiced: 97,
    avg_interview_score: 56,
    last_question_practiced: '2024-10-12',
  },
  {
    id: 16,
    name: 'Fatima Khan',
    email: 'fatima.khan@gmail.com',
    questions_practiced: 61,
    avg_interview_score: 80,
    last_question_practiced: '2024-10-16',
  },
  {
    id: 17,
    name: 'Jose Rodriguez',
    email: 'jose.rodriguez@gmail.com',
    questions_practiced: 32,
    avg_interview_score: 60,
    last_question_practiced: '2024-10-09',
  },
  {
    id: 18,
    name: 'Chloe Anderson',
    email: 'chloe.anderson@gmail.com',
    questions_practiced: 63,
    avg_interview_score: 87,
    last_question_practiced: '2024-10-11',
  },
  {
    id: 19,
    name: 'Isaac Wright',
    email: 'isaac.wright@gmail.com',
    questions_practiced: 13,
    avg_interview_score: 98,
    last_question_practiced: '2024-10-08',
  },
  {
    id: 20,
    name: 'Sophia Taylor',
    email: 'sophia.taylor@gmail.com',
    questions_practiced: 39,
    avg_interview_score: 88,
    last_question_practiced: '2024-10-17',
  },
  {
    id: 21,
    name: 'Kevin Nguyen',
    email: 'kevin.nguyen@gmail.com',
    questions_practiced: 70,
    avg_interview_score: 54,
    last_question_practiced: '2024-10-17',
  },
  {
    id: 22,
    name: 'Priya Singh',
    email: 'priya.singh@gmail.com',
    questions_practiced: 69,
    avg_interview_score: 64,
    last_question_practiced: '2024-10-16',
  },
  {
    id: 23,
    name: 'Leo Wilson',
    email: 'leo.wilson@gmail.com',
    questions_practiced: 60,
    avg_interview_score: 74,
    last_question_practiced: '2024-10-12',
  },
  {
    id: 24,
    name: 'Hassan Baker',
    email: 'hassan.baker@gmail.com',
    questions_practiced: 94,
    avg_interview_score: 79,
    last_question_practiced: '2024-10-21',
  },
  {
    id: 25,
    name: 'Leah Thomas',
    email: 'leah.thomas@gmail.com',
    questions_practiced: 14,
    avg_interview_score: 96,
    last_question_practiced: '2024-10-17',
  },
  {
    id: 26,
    name: 'Mei Chen',
    email: 'mei.chen@gmail.com',
    questions_practiced: 73,
    avg_interview_score: 78,
    last_question_practiced: '2024-10-18',
  },
  {
    id: 27,
    name: 'Olivia Moore',
    email: 'olivia.moore@gmail.com',
    questions_practiced: 29,
    avg_interview_score: 85,
    last_question_practiced: '2024-10-19',
  },
  {
    id: 28,
    name: 'Emma Clark',
    email: 'emma.clark@gmail.com',
    questions_practiced: 59,
    avg_interview_score: 55,
    last_question_practiced: '2024-10-09',
  },
  {
    id: 29,
    name: 'Emily Miller',
    email: 'emily.miller@gmail.com',
    questions_practiced: 87,
    avg_interview_score: 53,
    last_question_practiced: '2024-10-10',
  },
  {
    id: 30,
    name: 'Ahmed Ali',
    email: 'ahmed.ali@gmail.com',
    questions_practiced: 45,
    avg_interview_score: 83,
    last_question_practiced: '2024-10-10',
  },
];

export default function UsersPage() {
  const [sortId, setSortId] = useState<Column['id']>('id');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const { user, isAdmin } = useMe();
  const navigate = useNavigate();

  useOnChange({
    value: isAdmin,
    defaultValue: true,
    onChange: () => {
      if (user && !isAdmin) {
        navigate('/');
      }
    },
  });

  return (
    <MainLayout>
      <div className="flex flex-col transition-all">
        <Box className=" flex flex-row content-center items-left">
          <div className="flex-1 flex-row content-center mb-12 mt-4 ">
            <Typography
              variant="h5"
              className="!text-base sm:!text-xl md:!text-xl lg:!text-2xl inline-block !mr-4"
            >
              <b>Users </b>
            </Typography>
          </div>
          <div className="flex flex-1 justify-end">
            <div className="bg-[url('/images/ccoc.png')] bg-fit bg-no-repeat bg-center w-14 h-14 aspect-square"></div>
          </div>
        </Box>

        <Box className="!bg-slate-200 p-4 rounded-2xl mb-4 ">
          <TableContainer sx={styles.tableContainer} className="rounded-xl">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(({ id, align, minWidth, label }) => (
                    <TableCell
                      key={id}
                      align={align}
                      style={{ minWidth: minWidth }}
                      sortDirection={sortDirection}
                    >
                      <TableSortLabel
                        active={sortId === id}
                        direction={sortDirection}
                        onClick={() => {
                          setSortId(id);
                          setSortDirection(
                            sortDirection === 'asc' ? 'desc' : 'asc'
                          );
                        }}
                      >
                        {label}
                      </TableSortLabel>
                    </TableCell>
                  ))}

                  <TableCell key="nav" align="center">
                    {/* Empty label, used for options */}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData.map(row => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map(column => {
                        const value = row[column.id as keyof typeof row];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Typography>{value as any}</Typography>
                          </TableCell>
                        );
                      })}

                      <TableCell key="nav">
                        <IconButton>
                          <MoreVertOutlined color="action" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
    </MainLayout>
  );
}

const styles: { [key: string]: any } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'top',
    px: 3,
    paddingTop: 4,
    mb: 2,
  },
  doneButton: {
    height: 40,
  },
  paper: {
    borderWidth: 20,
    borderColor: '#F5F5F5',
    borderRadius: 5,
    overflow: 'hidden',
  },
  tableContainer: {
    backgroundColor: 'white',
  },
};
