import { ExpandCircleDownOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { tokenState } from '../states/auth';
import { uiState } from '../states/uiState';
import { Footer } from './Footer';
import Header from './Header';
import SideBar from './SideBar';
import { SubscriptionBanner } from './SubscriptionBanner';
type Props = PropsWithChildren<{
  header?: ReactNode;
  subHeader?: ReactNode;
  footer?: ReactNode;
  showHeaderButtons?: boolean;
  showBackButton?: boolean;
  showSubscriptionBanner?: boolean;
  hideMenu?: boolean;
}>;

export function MainLayout({
  children,
  footer,
  header,
  hideMenu,
  subHeader,
  showSubscriptionBanner,
}: Props) {
  const [ui, setUi] = useRecoilState(uiState);
  const token = useRecoilValue(tokenState);

  const { menuOpen } = ui;
  const setMenuOpen = (menuOpen: boolean) => setUi({ ...ui, menuOpen });

  const isMobile = window.innerWidth <= 768;

  return (
    <>
      {token && !hideMenu && (
        <div
          className={`fixed top-0 left-0 bottom-0 transition-all z-30 h-dvh ${
            isMobile && menuOpen && 'shadow-sideBar'
          } ${menuOpen ? 'w-64' : 'w-16'}`}
        >
          <IconButton
            className="text-white !absolute !-right-5 !top-4"
            onClick={() => setMenuOpen(!menuOpen)}
            aria-label="delete"
          >
            <ExpandCircleDownOutlined
              className={`origin-center transition-transform bg-white text-textBorder rounded-full  ${
                !menuOpen ? '-rotate-90' : 'rotate-90'
              } `}
            />
          </IconButton>
          <SideBar menuOpen={menuOpen} />
        </div>
      )}

      <div className="flex">
        <div
          className={`flex flex-col px-8 transition-all ${
            token && !hideMenu
              ? !menuOpen
                ? 'ml-16 w-[calc(100%-64px)]'
                : 'ml-64 w-[calc(100%-256px)]'
              : 'w-full'
          } `}
        >
          <div>
            {!token && <Header />}
            {showSubscriptionBanner && <SubscriptionBanner />}
            {header}
            {subHeader}
          </div>
          <div className="grow">{children}</div>
          {footer && (
            <div
              className={`fixed bottom-0 w-full right-0 ${
                menuOpen ? 'pl-64' : 'pl-16'
              }`}
            >
              <Footer>{footer}</Footer>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
