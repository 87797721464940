import { AxiosError } from 'axios';
import moment from 'moment';
import { useFeatureFlagVariantKey } from 'posthog-js/react';
import { useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { useRecoilValue } from 'recoil';
import { Constants } from '../data/constants';
import { User } from '../data/user';
import { clientState } from '../states/auth';

function useFetchMe(
  options?: UseQueryOptions<{ data: User; success: boolean }, AxiosError>
) {
  const client = useRecoilValue(
    clientState({
      contentType: 'application/json',
    })
  );

  const result = useQuery<{ data: User; success: boolean }, AxiosError>(
    ['/users/me'],
    () => client.get('/users/me').then(r => r.data),
    {
      ...options,
      onSuccess(...args) {
        options?.onSuccess?.(...args);
      },
      onError(e) {
        console.error(e);
      },
    }
  );

  return result;
}

export function useMe() {
  const { data, isLoading, isSuccess } = useFetchMe();
  const trialDaysFlagValue = useFeatureFlagVariantKey(
    Constants.POSTHOG_FEATURE_FLAG.trialDays
  );

  const maxTrialDays = useMemo(() => {
    return trialDaysFlagValue === '3' ? 3 : 7;
  }, [trialDaysFlagValue]);

  const trialRemainingDays = useMemo(() => {
    if (data?.data != null && data.data.isFree) {
      const createdDate = new Date(data.data.createdAt);
      const currentDate = new Date();
      const elapsedDays = moment(currentDate).diff(moment(createdDate), 'days');
      const daysRemaining = Math.max(maxTrialDays - elapsedDays, 0);
      return daysRemaining;
    }
    return 0;
  }, [data?.data, maxTrialDays]);

  const canPractice = useMemo(
    () => !data?.data.isFree || trialRemainingDays > 0,
    [data?.data.isFree, trialRemainingDays]
  );

  const isFree = useMemo(() => data?.data.isFree, [data?.data.isFree]);
  const isAdmin = useMemo(
    () =>
      data?.data.email.includes('@fractallabs.dev') ||
      data?.data.email?.includes('@practiceinterviews.com'),
    [data?.data.email]
  );

  return {
    user: data?.data,
    isAdmin,
    isFree,
    isLoading,
    isSuccess,
    maxTrialDays,
    trialRemainingDays,
    canPractice,
  };
}
