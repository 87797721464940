import { Box, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMe } from '../middleware/useMe';
import { APP_PATHS } from '../routes/paths';

export function SubscriptionBanner() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user, trialRemainingDays, maxTrialDays } = useMe();

  const trialPeriodDescription = useMemo(() => {
    if (trialRemainingDays >= 1) {
      return `🚀 ${trialRemainingDays} ${
        trialRemainingDays === 1 ? 'day' : 'days'
      } left in your free trial! Upgrade now for full
        access to the platform.`;
    }

    return `Your ${maxTrialDays} days free trial has expired. Upgrade to Premium today to continue practicing`;
  }, [trialRemainingDays, maxTrialDays]);

  return user?.isFree ? (
    <Box sx={{ backgroundColor: theme.palette.primary.main }}>
      <Typography
        textAlign="center"
        color="white"
        fontWeight="bold"
        sx={{ textDecoration: 'underline', py: 2, cursor: 'pointer' }}
      >
        <span
          onClick={() => {
            navigate(APP_PATHS.account);
          }}
        >
          {trialPeriodDescription}
        </span>
      </Typography>
    </Box>
  ) : null;
}
