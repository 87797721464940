import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from '../../components/MainLayout';
import { useOnChange } from '../../hooks/useOnChange';
import { useMe } from '../../middleware/useMe';

export default function DashboardPage() {
  const { user, isAdmin } = useMe();
  const navigate = useNavigate();

  useOnChange({
    value: isAdmin,
    defaultValue: true,
    onChange: () => {
      if (user && !isAdmin) {
        navigate('/');
      }
    },
  });
  return (
    <MainLayout>
      <div className="flex flex-col transition-all">
        <Box className=" flex flex-row content-center items-left">
          <div className="flex-1 flex-row content-center mb-12 mt-4 ">
            <Typography
              variant="h5"
              className="!text-base sm:!text-xl md:!text-xl lg:!text-2xl inline-block !mr-4"
            >
              <b>Dashboard </b>
            </Typography>
            <Button
              className="!rounded-full !text-md md:text-xl !font-bold !normal-case  !bg-white !px-4 !py-2 "
              variant="outlined"
              href="/resources"
            >
              Submit data request{' '}
            </Button>
          </div>
          <div className="flex flex-1 justify-end">
            <div className="bg-[url('/images/ccoc.png')] bg-fit bg-no-repeat bg-center w-14 h-14 aspect-square"></div>
          </div>
        </Box>
        <Box
          className={` mb-16 flex flex-col content-center items-left bg-slate-200 rounded-xl`}
        >
          <div className="flex flex-row content-center items-left">
            <div className="w-60">
              <div className=" bg-white rounded-xl m-4 mr-0">
                <div className="bg-[url('/images/dummyTL.png')] bg-fit bg-no-repeat bg-center w-full h-48 aspect-square rounded-xl"></div>
              </div>
              <div className=" bg-white rounded-xl m-4 mr-0">
                <div className="bg-[url('/images/dummyML.png')] bg-fit bg-no-repeat bg-center w-full h-48 aspect-square rounded-xl"></div>
              </div>
            </div>

            <div className="grow bg-white rounded-xl m-4">
              <div className="bg-[url('/images/dummyTR.png')] bg-fit bg-no-repeat bg-left w-full h-full rounded-xl"></div>
            </div>
          </div>
          <div className="flex flex-row content-center items-left">
            <div className="w-60">
              <div className=" bg-white rounded-xl m-4 mt-0 mr-0">
                <div className="bg-[url('/images/dummyBL.png')] bg-fit bg-no-repeat bg-center w-full h-48 aspect-square rounded-xl"></div>
              </div>
            </div>
            <div className="grow m-4 mt-0 flex">
              <div className="flex flex-1 bg-white rounded-xl mr-2">
                <div className="bg-[url('/images/dummyBM.png')] bg-fit bg-no-repeat bg-center w-full h-48 aspect-square rounded-xl"></div>
              </div>
              <div className="flex flex-1 bg-white rounded-xl ml-2">
                <div className="bg-[url('/images/dummyBR.png')] bg-fit bg-no-repeat bg-center w-full h-48 aspect-square rounded-xl"></div>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </MainLayout>
  );
}
