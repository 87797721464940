import {
  BentoOutlined,
  EmailOutlined,
  FeedbackOutlined,
  HomeOutlined,
  InfoOutlined,
  StackedLineChartOutlined,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  ListItem,
  ListItemButton,
  ListItemText,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useMe } from '../middleware/useMe';
import { APP_PATHS } from '../routes/paths';
import { tokenState } from '../states/auth';
import {
  homeButtonClickedState,
  supportVisibilityState,
} from '../states/header';
import { theme } from '../styles/theme';
import { stripUsername } from '../utils/user';
import TableIcon from './icons/TableIcon';
import IsFreeTooltip from './IsFreeTooltip';
import Logo from './Logo';
import PIDialog from './PIDialog';
import SmallLogo from './SmallLogo';

type NavItem = {
  type?: string;
  text?: string;
  path?: string;
  disabled?: boolean;
  tooltip?: string;
  action?: () => void;
  icon?: any;
};

const DRAWER_WIDTH = 240;

export function SideBar({ menuOpen }: { menuOpen: boolean }) {
  const navigate = useNavigate();
  const location = useLocation();
  const setHomeButtonClicked = useSetRecoilState(homeButtonClickedState);
  const setSupportVisibilityState = useSetRecoilState(supportVisibilityState);
  const [emailDialogDismissed, setEmailDialogDismissed] =
    useState<boolean>(true);

  const { user, isFree, isAdmin } = useMe();

  const token = useRecoilValue(tokenState);

  const goToHome = useCallback(() => {
    if (location.pathname !== APP_PATHS.startAnswering) {
      navigate('/');
      setHomeButtonClicked(false);
    } else {
      setHomeButtonClicked(true);
    }
  }, [navigate, location, setHomeButtonClicked]);

  const openLink = useCallback((url: string, target?: boolean) => {
    const newTab = shouldOpenInNewTab(window.location.pathname);
    window.open(url, (target || newTab) ? '_blank' : '_self');
  }, []);

  const shouldOpenInNewTab = (pathname: string): boolean => {
    const paths = ['/select-question-type', '/start-answering', '/feedback'];
    return paths.some(path => pathname.endsWith(path));
  };

  const showCustomerSupport = useCallback(() => {
    setSupportVisibilityState('maximized');
  }, [setSupportVisibilityState]);

  const navItems: NavItem[] = useMemo(
    () => [
      { text: 'Home', path: '/', action: goToHome, icon: <HomeOutlined /> },
      {
        text: 'Feedback',
        path: APP_PATHS.answerFeedback,
        action: () => openLink(APP_PATHS.answerFeedback),
        icon: <FeedbackOutlined />,
      },

      {
        text: 'Analytics',
        disabled: isFree,
        tooltip: 'Upgrade to PREMIUM to access this feature!',
        path: APP_PATHS.analytics,
        action: () => openLink(APP_PATHS.analytics),
        icon: <StackedLineChartOutlined />,
      },
      {
        text: 'Resources',
        disabled: isFree,
        tooltip: 'Upgrade to PREMIUM to access this feature!',
        path: APP_PATHS.resources,
        action: () => openLink(APP_PATHS.resources),
        icon: <BentoOutlined />,
      },
      {
        text: 'Contact us',
        path: APP_PATHS.resources,
        action: () => setEmailDialogDismissed(false),
        icon: <EmailOutlined />,
      },
      {
        text: 'Provide feedback',
        path: APP_PATHS.resources,
        action: showCustomerSupport,
        icon: <InfoOutlined />,
      },
    ],
    [goToHome, isFree, showCustomerSupport, openLink]
  );

  const adminSidebarItems: NavItem[] = [
    { type: 'divider', text: ' ' },
    {
      text: 'Dashboard',
      path: APP_PATHS.dashboard,
      action: () => openLink(APP_PATHS.dashboard),
      icon: <TableIcon />,
    },
    {
      text: 'Users',
      path: APP_PATHS.users,
      action: () => openLink(APP_PATHS.users),
      icon: <TableIcon />,
    },
    {
      text: 'Admin Dashboard',
      path: APP_PATHS.adminDashboard,
      action: () => openLink(`${APP_PATHS.adminDashboard}?token=${token}`, true),
      icon: <TableIcon />,
    },
    { type: 'divider', text: ' ' },
  ];

  const SidebarItems = isAdmin ? [...navItems, ...adminSidebarItems] : navItems;

  const renderNavItems = (isMobile: boolean) => {
    return SidebarItems.map((item, i) => {
      if (item.type === 'divider') {
        return (
          <div key={i} className="border-t px-2 pt-2 pb-4 mt-2 border-textGray">
            {item.text && menuOpen && (
              <Typography className="!text-slate-400 ">{item.text}</Typography>
            )}
          </div>
        );
      } else {
        return isMobile ? (
          <ListItem disablePadding key={item.text}>
            <Tooltip title={item.text} placement="right" arrow key={i}>
              <span>
                <IsFreeTooltip
                  key={i}
                  isFree={item.disabled}
                  tooltip={item.tooltip ?? ''}
                >
                  <span>
                    <ListItemButton
                      disabled={item.disabled}
                      onClick={() => {
                        item.action && item.action();
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            color="primary"
                          >
                            {item.text}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </span>
                </IsFreeTooltip>
              </span>
            </Tooltip>
          </ListItem>
        ) : (
          <Tooltip
            title={!menuOpen && item.text}
            placement="bottom-start"
            arrow
          >
            <span>
              <IsFreeTooltip
                isFree={item.disabled}
                tooltip={item.tooltip ?? ''}
              >
                <span>
                  <Button
                    className="w-full !justify-start !my-2 !mx-1 !mr-3 !normal-case"
                    sx={
                      item.disabled
                        ? {
                            color: 'text.disabled',
                            backgroundColor: 'background.paper',
                            '&:hover': {
                              backgroundColor: 'background.paper',
                            },
                          }
                        : {
                            color: '#000000',
                          }
                    }
                    startIcon={item.icon}
                    disabled={item.disabled}
                    key={item.text}
                    onClick={item.action}
                  >
                    {menuOpen && item.text}
                  </Button>
                </span>
              </IsFreeTooltip>
            </span>
          </Tooltip>
        );
      }
    });
  };

  return (
    <div
      className={`shadow px-3 py-2 h-screen overflow-hidden flex
        ${menuOpen ? 'w-64' : 'w-16'}
      `}
    >
      <div className="flex flex-col overflow-hidden">
        <div className={`${menuOpen ? 'w-64' : 'w-16'} grow py-2`}>
          <div className="!px-2">{menuOpen ? <Logo /> : <SmallLogo />}</div>
          {user?.isFree && menuOpen && (
            <Button
              variant="contained"
              onClick={() => openLink(APP_PATHS.account)}
              sx={styles.button}
              className="!w-56 !rounded-full !text-base !font-bold !normal-case !my-4 "
            >
              Upgrade
            </Button>
          )}
          {renderNavItems(false)}
        </div>
        <div className="px-1 w-full">
          {menuOpen ? (
            <Box
              onClick={() => openLink(APP_PATHS.account)}
              sx={styles.usernameContainer}
              display="flex"
              alignItems="center"
              borderRadius={100}
            >
              <Box width={'98%'} sx={styles.username}>
                <Typography>{user?.firstName}</Typography>
              </Box>
              <Avatar
                children={stripUsername(user?.firstName ?? '')}
                className="!h-6 !w-6 !text-sm"
              />
            </Box>
          ) : (
            <Avatar
              onClick={() => openLink(APP_PATHS.account)}
              className="!h-8 !w-8 !text-sm"
              children={stripUsername(user?.firstName ?? '')}
            />
          )}
        </div>
      </div>
      <PIDialog
        title="Email us"
        description={
          <span>
            Need help? Found a bug? Have a feature request? Please feel free to
            contact us:{' '}
            <b>
              <a
                href="mailto: jeff@practiceinterviews.com"
                style={{
                  textDecoration: 'underline',
                  color: theme.palette.primary.main,
                }}
              >
                jeff@practiceinterviews.com
              </a>
            </b>
          </span>
        }
        open={!emailDialogDismissed}
        onClose={() => {
          setEmailDialogDismissed(true);
        }}
        buttons={[
          {
            text: 'Okay',
            color: theme.palette.primary.main,
          },
        ]}
      />
    </div>
  );
}

const styles: { [key: string]: SxProps<Theme> } = {
  container: {
    paddingTop: 1,
    paddingBottom: 1,
    zIndex: 999,
    minHeight: 68,
    alignItems: 'center',
    display: 'flex',
    minWidth: '100%',
    backgroundColor: 'white',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
  },
  header: {
    padding: 0,
    flex: 1,
  },
  usernameContainer: {
    marginRight: 0,
    backgroundColor: '#F5F5F5',
    padding: 1,
  },
  username: {
    marginLeft: 2,
    marginRight: 2,
  },
  avatar: {
    fontSize: 12,
    width: 24,
    height: 24,
  },
  button: {
    textTransform: 'none',
    marginRight: 2,
    fontWeight: 600,
  },
  upgradeButton: {
    textTransform: 'none',
    marginRight: 2,
    fontWeight: 600,
  },
  buttons: {
    display: { xs: 'none', sm: 'flex' },
    flex: 1,
  },
  menuButton: {
    display: { xs: 'flex', sm: 'none' },
    flex: 1,
  },
  drawer: {
    display: { xs: 'block', sm: 'none' },
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: DRAWER_WIDTH,
    },
  },
};

export default SideBar;
