import { ReactNode, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { Loading } from '../components/Loading';
import { tokenState } from '../states/auth';

interface Props {
  children: ReactNode;
}

export function PrivateRoute({ children }: Props) {
  const token = useRecoilValue(tokenState);

  if (!!token) {
    return <Suspense fallback={<Loading />}>{children}</Suspense>;
  }

  return <Navigate to="/" />;
}
