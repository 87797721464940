import * as React from 'react';

function TableIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 16" fill="none" {...props}>
      <path
        d="M1.375 4.712l-.349-.359-.151.148v.21h.5zM5.313.875v-.5h-.204l-.145.142.349.358zm7.312 2.192h-.5.5zm0 9.866h.5-.5zm-11.25 0h.5-.5zm4.5-7.673v.5h.5v-.5h-.5zm-4.5 0h-.5.5zm.349-.19L5.66 1.233 4.964.517 1.026 4.353l.698.717zm1.901 10.555h6.75v-1h-6.75v1zm8.5-12.558v9.866h1V3.067h-1zm-8.5 11.558c-.979 0-1.75-.77-1.75-1.692h-1c0 1.499 1.244 2.692 2.75 2.692v-1zm6.75 1c1.507 0 2.75-1.193 2.75-2.692h-1c0 .922-.771 1.692-1.75 1.692v1zm0-14.25c.979 0 1.75.77 1.75 1.692h1c0-1.499-1.243-2.692-2.75-2.692v1zm-5.062 0h.562v-1h-.562v1zm.562 0h4.5v-1h-4.5v1zm-5 3.337v.548h1v-.548h-1zm0 .548v7.673h1V5.26h-1zm4.5-4.385V5.26h1V.875h-1zm.5 3.885h-4.5v1h4.5v-1zm-1.5 3.192H7v-1H4.375v1zm2.625 0h2.625v-1H7v1zm-2.625 5.48H7v-1H4.375v1zm2.625 0h2.625v-1H7v1zm-.5-5.98v2.74h1v-2.74h-1zm0 2.74v2.74h1v-2.74h-1zm4.375 1.99v-1.99h-1v1.99h1zm0-1.99v-1.99h-1v1.99h1zm-7.75-1.99v1.99h1v-1.99h-1zm0 1.99v1.99h1v-1.99h-1zm7.25-.5H7v1h3.375v-1zM7 9.692H3.625v1H7v-1zm2.625 3.74c.69 0 1.25-.559 1.25-1.25h-1a.25.25 0 01-.25.25v1zm-5.25-1a.25.25 0 01-.25-.25h-1c0 .691.56 1.25 1.25 1.25v-1zm5.25-4.48a.25.25 0 01.25.25h1c0-.69-.56-1.25-1.25-1.25v1zm-5.25-1c-.69 0-1.25.56-1.25 1.25h1a.25.25 0 01.25-.25v-1z"
        fill="#000"
      />
    </svg>
  );
}

export default React.memo(TableIcon);
