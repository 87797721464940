import { atom } from 'recoil';

type AudioStateType = {
  currentTime: number;
  duration: number;
};

export const audioState = atom<AudioStateType>({
  key: 'audioState',
  default: {
    currentTime: 0,
    duration: 0,
  },
});
