import { useEffect } from 'react';

interface Props {
  callback: () => void;
  interval: number;
}

export function useInterval({ callback, interval }: Props) {
  useEffect(() => {
    const intervalId = setInterval(() => {
      callback();
    }, interval);

    return () => {
      intervalId != null && clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
