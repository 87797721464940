import { useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { affiliateSourceState } from '../states/auth';
import useOnInitialMount from './useOnInitialMount';

export function useParseParams() {
  const [searchParams] = useSearchParams();
  const source = searchParams.get('source');
  const setAffiliateSource = useSetRecoilState(affiliateSourceState);

  useOnInitialMount(() => {
    if (source != null) {
      setAffiliateSource(source);
    }
  });
}
