class ContantValues {
  TEXTFIELD_ONE_ROW_HEIGHT = 23;
  API_BASE_URLS = {
    dev: "https://pi-backend-dev-401883a6ed36.herokuapp.com",
    stage: "https://pi-backend-stage-983c522c7c19.herokuapp.com",
    production: "https://pi-backend-prod-cd0ba6433021.herokuapp.com",
  };
  SOCKET_BASE_URLS = {
    dev: "https://talkwise-backend-dev.herokuapp.com",
    stage: "https://talkwise-backend-stage.herokuapp.com",
    production: "https://talkwise-backend-prod.herokuapp.com",
  };
  POSTHOG_FEATURE_FLAG = {
    trialDays: "trial-days-comparison",
    subscriptionPricing: "subscription-pricing-comparison"
  }
}

export const Constants = new ContantValues();
